body {
  font-family: 'Open Sans', sans-serif !important;
}

.logo {
  height: 68px;
  padding: 0;
  margin: 0 0;
  overflow: hidden;
  transition: all 0.5s ease;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-image {
  height: 36px;
  margin: 16px 0;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.5s ease;
}

.logo-text {
  line-height: 32px;
  margin: 0 0;
  width: 170px;
  font-size: 22pt;
  font-weight: 400;
  color: #a7a9ac;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.5s ease;
  text-align: left;
}

.logo-text:hover {
  color: #00a79d;
}

.trigger:hover {
  color: #00a79d;
}

.trigger {
  color: #a7a9ac;
  font-size: 18pt;
  padding-left: 1em;
  cursor: pointer;
  transition: color .3s;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
